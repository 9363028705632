@import "style-constants.scss";

.question-wrapper {
    text-align: left;

    .question {
        font-size: 1.125rem;
        line-height: 1.75rem;
        font-weight: 500;
        color: $primary-text-color;
    }

    .answear {
        font-size: 1rem;
        line-height: 1.5rem;
        color: $primary-text-color-500;
        max-width: 65ch;
    }
}
