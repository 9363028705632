@import "style-constants.scss";

.contact-banner-wrapper {
    margin-top: 150px;
    position: relative;

    .contact-banner-container {
        border: 1px solid $primary-text-color-200;
        background-color: $white;
        border-radius: 1.5rem;
        padding-bottom: 60px;
        max-width: 90vw;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        z-index: 2;

        .title {
            font-size: 3rem;
            font-weight: 900;
            letter-spacing: -.025em;
            max-width: 80rem;
            margin-left: auto;
            margin-right: auto;
            margin-top: 70px;
            padding-left: 25px;
            padding-right: 25px;
            color: $primary-text-color;
            position: relative;
            z-index: 2;
        }

        .text {
            color: $primary-text-color-500;
            font-size: 1.125rem;
            line-height: 1.75rem;
            font-style: italic;
            text-align: center;
            max-width: 40rem;
            margin-left: auto;
            margin-right: auto;
            padding-left: 30px;
            padding-right: 30px;
            position: relative;
            z-index: 2;
        }

        .custom-button-small {
            position: relative;
            z-index: 2;
        }

        .triangles-wrapper {

            .triangle-right {
                position: absolute;
                bottom: 0;
                width: 0;
                height: 0;
                border-top: 180px solid transparent;
                border-left: 75vw solid $primary-color-200;
                border-bottom-left-radius: 1.5rem;
                z-index: 1;
            }

            .triangle-left {
                position: absolute;
                bottom: 0;
                right: 0;
                width: 0;
                height: 0;
                border-top: 180px solid transparent;
                border-right: 75vw solid $primary-color-300;
                border-bottom-right-radius: 1.5rem;
                z-index: 1;
            }

            .triangle-up {
                width: 0;
                height: 0;


                position: absolute;
                bottom: 0;
                right: calc(50% - 35vw);
                width: 0;
                height: 0;
                border-left: 35vw solid transparent;
                border-right: 35vw solid transparent;
                border-bottom: 70px solid $primary-color-400;
                border-bottom-right-radius: 1.5rem;
                z-index: 1;
            }
        }
    }

    rect {
        background-image: radial-gradient(#D1D5DB 2px, transparent 0);
        background-size: 40px 40px;
        z-index: 1;
        width: 30%;
        height: 100%;
        position: absolute;
        top: -20%;
        left: 35%;
    }

    .gray-grid {
        background: #F9FAFB;
        background-size: 40px 40px;
        z-index: 0;
        width: 40%;
        height: 110%;
        position: absolute;
        top: -25%;
        left: 0;
        border-top-right-radius: 1.5rem;
        border-bottom-right-radius: 1.5rem;
    }
}

@media only screen and (max-width: $laptop) {
    .contact-banner-wrapper {
        rect {
            width: 60%;
        }
    }
}

@media only screen and (max-width: $mobile-l) {
    .contact-banner-wrapper {
    
        .contact-banner-container {
    
            .title {
                font-size: 1.875rem;
                line-height: 1.75rem;
            }
    
            .text {
                font-size: 1rem;
                letter-spacing: -.025em;
                line-height: 1.25rem;
            }
        }
    }
}