@import "style-constants.scss";

.swiper-wrapper {

    .custom-swiper {

        .swiper-pagination-bullet {
            width: 50px;
            border-radius: .5rem;
        }

        .swiper-pagination-bullet-active {
            background: $primary-color;
        }
        
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 1.5rem;
            border-bottom-left-radius: 1.5rem;
        }
    }
}
