@import "style-constants.scss";

.home-page-wrapper {
    margin-top: 120px;
}

@media only screen and (max-width: $mobile-l) {
    .home-page-wrapper {
        margin-top: 50px;
    }
}
