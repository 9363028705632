@import "style-constants.scss";

.custom-button-small {
    background-color: $primary-color !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
    text-decoration: none !important;
    text-transform: none !important;
}

.custom-button-medium {
    background-color: $primary-color !important;
    font-size: 1.125rem !important;
    font-weight: 500 !important;
    text-decoration: none !important;
    text-transform: none !important;
}

.small {
    width: 140px;
    height: 41px;
}

.medium {
    width: 165px;
    height: 52px;
    font-size: 1.125rem !important;
    font-weight: 500 !important;
}

.custom-button-small:hover {
    background-color: $complimentary-color !important;
}

.custom-button-medium:hover {
    background-color: $complimentary-color !important;
}
