@import "style-constants.scss";

.product-features-wrapper {
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding-left: 2rem;
    padding-right: 2rem;
    max-width: 90rem;
    margin-top: 70px;
    gap: 20px;
}

.product-features-wrapper > * {
    flex-basis: 100%;
}

@media only screen and (max-width: $tablet) {
    .product-features-wrapper {
        display: grid;
        grid-template-columns: 50% 50%;
    }
}

@media only screen and (max-width: $mobile-l) {
    .product-features-wrapper {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
    }
}

