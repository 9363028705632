@import "style-constants.scss";

.custom-feature-wrapper {
    max-width: 20rem;
    display: flex;
    flex-direction: column;

    svg {
        width: 64px;
        height: 64px;
        color: $primary-color;
        align-self: flex-start;
    }

    .feature-title {
        font-size: 1rem;
        font-weight: 500;
        margin-top: 1.5rem;
        color: $primary-text-color;
        text-align: left;
    }

    .feature-text {
        font-size: 1rem;
        font-weight: 400;
        color: $primary-text-color-500;
        text-align: left;
    }
}
