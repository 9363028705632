@import "style-constants.scss";

.main-block-wrapper {
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: space-between;
    margin-left: 25px;
    gap: 20px;
    max-width: calc(100% - 45px);

    .brand-name {
        font-style: italic;
        color: $primary-color;
    }

    .information-wrapper {
        text-align: start;

        .title-container {
            font-size: 3.75rem;
            line-height: 1;
            font-weight: 900;
            color: $primary-text-color;
            text-align: left;
            margin-top: 0;
        }

        .subtitle-container {
            font-size: 1.25rem;
            line-height: 1.75;
            font-weight: 400;
            color: $primary-text-color-500;
            text-align: left;
            margin-bottom: 30px;
        }

        .custom-button-small {
            margin-bottom: 30px;
        }

        .review-wrapper {
            display: flex;
            gap: 0.75rem;

            .stars {
                width: 1.25rem;
                height: 1.25rem;
                align-self: center;
            }

            .text-container {
                font-size: 0.875rem;
                line-height: 1.25;
                font-weight: 400;
                color: $primary-text-color-500;

                border-left-width: 1px;
                border-color: #D1D5DB;
                border-left-style: solid;
                align-self: center;
                padding-left: 0.75rem;
            }
        }
    }

    .visuals-wrapper {
        position: relative;

        img {
            width: 100%;
            border-top-left-radius: 1.5rem;
            border-bottom-left-radius: 1.5rem;
            position: relative;
            z-index: 2;
            margin-top: 50px;
        }

        rect {
            background-image: radial-gradient(#D1D5DB 2px, transparent 0);
            background-size: 40px 40px;
            z-index: 1;
            width: 70%;
            height: 100%;
            position: absolute;
            right: 0;
            top: -20%;
            right: 30%;
        }

        .gray-grid {
            background: #F9FAFB;
            background-size: 40px 40px;
            z-index: 0;
            width: 40%;
            height: 135%;
            position: absolute;
            right: 0;
            top: -25%;
            right: 0;
            border-top-left-radius: 1.5rem;
            border-bottom-left-radius: 1.5rem;
        }
    }
}

@media only screen and (max-width: $laptop) {
    .main-block-wrapper {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        margin: 0;

        .information-wrapper {
            text-align: start;
            align-self: center;
            max-width: 90vw;
        }

        .visuals-wrapper {
            align-self: center;

            img {
                max-width: 90vw;
                margin-top: 30px;
                border-top-left-radius: 1.5rem;
                border-bottom-left-radius: 1.5rem;
                border-top-right-radius: 1.5rem;
                border-bottom-right-radius: 1.5rem;
            }

            rect {
                width: 0;
                height: 0;
            }

            .gray-grid {
                width: 0;
                height: 0;
            }
        }
    }
}

@media only screen and (max-width: $mobile-l) {
    .main-block-wrapper {
        margin-top: 50px;

        .information-wrapper {
    
            .title-container {
                font-size: 2.25rem;
                line-height: 2.5rem;
                font-weight: 900;
            }
    
            .subtitle-container {
                font-size: 1.125rem;
                line-height: 1.5;
                font-weight: 400;
                color: $primary-text-color-500;
                text-align: left;
                margin-bottom: 20px;
            }
    
            .review-wrapper {
                gap: 0.75rem;
                max-width: 260px;

                .stars-container {
                    min-width: 60px;
                }
    
                .stars {
                    width: 0.75rem;
                    height: 0.75rem;
                    align-self: center;
                }
    
                .text-container {
                    font-size: 0.75rem;
                    line-height: 1;
                }
            }

            .review-wrapper > * {
                flex-basis: 100%;
            }
        }

        .visuals-wrapper {

            img {
                margin-top: 15px;
            }
        }
    }
}