@import "style-constants.scss";

.application-wrapper {

    .navigation-wrapper {
        display: flex;
        align-items: center;
        padding: 1.5rem;
        padding-left: 2rem;
        padding-right: 2rem;
        justify-content: space-between;

        .sidemenu-button {
            width: 35px;
            height: 35px;

            svg {
                width: 30px;
                height: 30px;
            }
        }

        .logo-container {
            display: flex;
            gap: 10px;
            text-decoration: none;
            align-items: center;

            .logo-image {
                width: 40px;
                flex-shrink: 0;
            }

            .company-name {
                font-size: 1.5rem;
                font-weight: 700;
                flex-shrink: 0;
                color: $primary-text-color;
            }
        }

        .menu-items-container {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            gap: 30px;
        }

        .menu-item {
            line-height: 1.5rem;
            text-decoration: none;
            font-size: .875rem;
            font-weight: 600;

            span {
                width: 100%;
                color: $primary-text-color;
                text-decoration: none;
            }
        }
    }
}

@media only screen and (max-width: $mobile-l) {
    .application-wrapper {
        .navigation-wrapper {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
    }
}