@import "style-constants.scss";

.secondary-block-wrapper {
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: space-between;
    margin-top: 150px;
    gap: 20px;
    align-items: center;
    max-width: calc(100% - 45px);

    .information-wrapper {
        .title-container {
            font-size: 2.25rem;
            line-height: 1;
            font-weight: 900;
            color: $primary-text-color;
            text-align: left;
            margin-top: 0;
        }

        .subtitle-container {
            font-size: 1.25rem;
            line-height: 1.75;
            font-weight: 400;
            color: $primary-text-color-500;
            text-align: left;
            margin-bottom: 30px;
        }
    }

    .visuals-wrapper-left {
        position: relative;

        img {
            width: 100%;
            border-top-right-radius: 1.5rem;
            border-bottom-right-radius: 1.5rem;
            position: relative;
            z-index: 2;
        }
    }

    .visuals-wrapper-right {
        position: relative;

        img {
            width: 100%;
            border-top-left-radius: 1.5rem;
            border-bottom-left-radius: 1.5rem;
            position: relative;
            z-index: 2;
        }
    }
}

.right-wrapper {
    margin-right: 0;
    margin-left: 25px;
}

@media only screen and (max-width: $laptop) {
    .secondary-block-wrapper {
        margin-top: 150px;
        display: flex;
        flex-direction: column;
        max-width: 100%;

        .information-wrapper {
            max-width: 90vw;
        }
    
        .visuals-wrapper-left, .visuals-wrapper-right {
            align-self: center;

            img {
                max-width: 90vw;
                border-top-left-radius: 1.5rem;
                border-bottom-left-radius: 1.5rem;
                border-top-right-radius: 1.5rem;
                border-bottom-right-radius: 1.5rem;
            }
        }
    }

    .right-wrapper {
        margin-left: 0;
    }
}

@media only screen and (max-width: $mobile-l) {
    .secondary-block-wrapper {
        margin-top: 70px;

        .information-wrapper {
            .subtitle-container {
                margin-bottom: 10px;
            }
        }
    }
}
