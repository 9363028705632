@import "style-constants.scss";

.custom-inforamtion-wrapper {
    
    .custom-inforamtion-title {
        font-weight: 800;
        font-size: 2.25em;
        color: $primary-text-color;
        text-align: center;
    }

    .custom-inforamtion-text {
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 400;
        color: $primary-text-color-500;
        text-align: center;
        margin-top: 1.25em;
        margin-bottom: 1.25em;
    }
}

@media only screen and (max-width: $mobile-l) {
    .custom-inforamtion-wrapper {
    
        .custom-inforamtion-title {
            font-size: 1.75rem;
        }
    }
}
