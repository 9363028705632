@import "style-constants.scss";

.product-details-wrapper {
    max-width: 90rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 150px;

    .product-banners-container {
        display: flex;
        justify-content: space-between;
        gap: 2rem;
        margin-top: 70px;
    }

    .product-banners-container > * {
        flex-basis: 100%;
    }
}

@media only screen and (max-width: $tablet) {
    .product-details-wrapper {
    
        .product-banners-container {
            flex-direction: column;
        }
    }
}

@media only screen and (max-width: $mobile-l) {
    .product-details-wrapper {
        margin-top: 70px;

        .product-banners-container {
            margin-top: 80px;
        }
    }
}

