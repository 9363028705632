@import "style-constants.scss";

.shop-page-wrapper {
    margin-top: 120px;
}

@media only screen and (max-width: $tablet) {
    .shop-page-wrapper {
        margin-top: 50px;
    }
}
