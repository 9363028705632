@import "style-constants.scss";

.footer-wrapper {
    margin-top: 150px;
    border-top: 1px solid $primary-text-color-200;
    padding-top: 4rem;
    padding-bottom: 4rem;

    .menu-items-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;

        .menu-item {
            color: $primary-color;
            text-decoration: none;
            font-size: .875rem;
            font-weight: 500;
        }
    }

    .social-items-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;
        margin-top: 20px;

        .social-button {
            
            svg {
                height: 30px;
                width: 30px;
            }
        }
    }

    .rights {
        margin-top: 20px;
        font-size: .75rem;
        color: $primary-text-color-500;
    }
}

@media only screen and (max-width: $mobile-l) {
    .footer-wrapper {
        .menu-items-container {
            flex-direction: column;
        }

        .social-items-container {
            .social-button {
                
                svg {
                    height: 20px;
                    width: 20px;
                }
            }
        }
    }
}
