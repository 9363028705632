@import "style-constants.scss";

.provider-overview-wrapper {
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: space-between;
    gap: 20px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 2rem;
    padding-right: 50px;
    max-width: 90rem;
    margin-top: 150px;

    .information-wrapper {
        text-align: start;
        align-self: center;

        .title-container {
            font-size: 2.25rem;
            line-height: 2.5rem;
            font-weight: 700;
            color: $primary-text-color;
            text-align: left;
            margin-top: 0;
        }

        .price-container {
            display: flex;
            font-weight: 600;
            font-size: 1.5rem;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;

            .stars-container {
                display: flex;
                align-items: center;

                .stars {
                    width: 1.25rem;
                    height: 1.25rem;
                    align-self: center;
                }
            }
        }

        .subtitle-container {
            font-size: 1rem;
            line-height: 1.5;
            font-weight: 400;
            color: $primary-text-color-500;
            text-align: left;
        }

        .review-wrapper {
            margin-top: 1rem;
            margin-bottom: 2rem;
            .text-wrapper {
                display: flex;
                align-items: center;
                margin-top: .5rem;

                svg {
                    width: 15px;
                    height: 15px;
                    color: #1ABAA8;
                }

                .text-container {
                    font-size: .875rem;
                    line-height: 1.25rem;
                    margin-left: .5rem;
                    font-weight: 400;
                    color: $primary-text-color-500;
                }
            }
        }
    }
}

@media only screen and (max-width: $tablet) {
    .provider-overview-wrapper {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        margin: 0;
        padding: 0;

        .information-wrapper {
            text-align: start;
            align-self: center;
            max-width: 90vw;
        }

        .visual-wrapper {
            max-width: 90vw;
            align-self: center;
        }
    }
}
