@import "style-constants.scss";

.comment-banner-wrapper {
    background-color: $primary-color;
    color: $white;
    padding-top: 70px;
    padding-bottom: 70px;
    margin-top: 150px;

    .comment-container {
        font-size: 1.875rem;
        line-height: 2.5rem;
        font-weight: 700;
        text-align: center;
        max-width: 60%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 15px;
        margin-top: 0;
    }

    .name-container {
        font-size: 1.125rem;
        line-height: 1.75rem;
        font-weight: 500;
        text-align: center;
    }
}

@media only screen and (max-width: $mobile-l) {
    .comment-banner-wrapper {
        margin-top: 70px;
        padding-top: 50px;
        padding-bottom: 50px;
    
        .comment-container {
            font-size: 1.25rem;
            line-height: 1.75rem;
            max-width: 80%;
        }
    }
}
