@import "style-constants.scss";

.custom-information-wrapper {
    display: flex;
    flex-direction: column;


    .custom-information-image {
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 1rem;
        }
    }

    .custom-information-text {
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 400;
        color: $primary-text-color-500;
        text-align: left;
        margin-top: 1.25em;
        margin-bottom: 1.25em;
    }
}