@import "style-constants.scss";

.menu-wrapper {
    margin-left: 1.5rem;
    min-width: 250px;

    .menu-items-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;

        .menu-item {
            text-decoration: none;
            font-size: 1.5rem;
            font-weight: 600;
            line-height: 1.75rem;

            span {
                width: 100%;
                color: $primary-text-color;
                text-decoration: none;
            }
        }
    }
}