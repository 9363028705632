@import "style-constants.scss";

.frequently-asked-questions-wrapper {
    max-width: 48rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 150px;

    .questions-title {
        font-size: 2.25rem;
        line-height: 2.5rem;
        font-weight: 800;
        color: $primary-text-color;
        margin-bottom: 2rem;
    }

    .questions > * {
        border-top: 1px solid $primary-text-color-200;
    }
}

@media only screen and (max-width: $mobile-l) {
    .frequently-asked-questions-wrapper {
        margin-top: 70px;

        .questions-title {
            font-size: 1.75rem;
        }
    }
}
