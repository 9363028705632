// Kabo colors

$primary-color-700: #DF9126;
$primary-color-400: #FCF5EB;
$primary-color-300: #FDF8F1;
$primary-color-200: #FEFCF9;

$primary-text-color-900: #111827; 
$primary-text-color-500: #6B7280;
$primary-text-color-200: #CBD5E1;

$primary-text-color: $primary-text-color-900; 

$primary-color: $primary-color-700;

$complimentary-color: #3A4403;

$black: #000000;
$white: #ffffff;

// Kabo Screen Sizes
$mobile-s: 321px;
$mobile-m: 376px;
$mobile-l: 460px;
$tablet: 769px;
$laptop: 1025px;
$laptop-l: 1441px;


// Colors

$primary-color-900: #e0cd21;
$primary-color-800: #002abe;
$primary-color-700-hover: #0F33B0;
$primary-color-600: #1e41d5;
$primary-color-500: #2449e1;
$primary-color-blue-light: #5E8DFA;
$primary-color-blue-lighter: #699DEE;
$blue-creamy: #8EAEFA;
$primary-color-100: #c5c9f6;
$primary-color-70: #EDEFFD;
$primary-color-90: #C5C9F64D;
$primary-color-light: #F3F4FD;
$primary-color-50: #FBD5FF;

$secondary-color-900: #006b2e;
$secondary-color-800: #008c44;
$secondary-color-700: #009e50;
$secondary-color-600: #00b05d;
$secondary-color-500: #00c069;
$secondary-color-400: #00cc7e;
$secondary-color-300: #16d696;
$secondary-color-250: #86E6C7;
$secondary-color-200: #7be1b5;
$secondary-color-150: #E7FBF4;
$secondary-color-100: #b3edd2;
$secondary-color-50: #e0f8ed;
$secondary-color-background: #ecfcf6;


$secondary-color: $secondary-color-300;

